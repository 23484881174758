import { createRouter, createWebHistory } from 'vue-router'

import Articles from '../views/Articles.vue'
import Filters from '../views/Filters.vue'

const routes = [
    {
        path: '/artikelen',
        name: 'Articles',
        component: Articles
    },
    {
        path: '/filters',
        name: 'Filters',
        component: Filters
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router