import { createApp } from 'vue'
import App from './App.vue'
import router from './router'



import axios from 'axios'
import VueAxios from 'vue-axios'



// Create App
let app = createApp(App)



app.use(VueAxios, axios)



axios.defaults.withCredentials = true;
axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true



// Mount App
app.use(router).mount('#app')