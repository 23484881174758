<template>
    <Menu ref="Menu" />
    <router-view class="router" />
</template>

<script>
import Menu from '@/views/Layout/Menu.vue';

export default {
    name: 'App',

    components: {
        Menu
    }
}
</script>

<style>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body, #app {
    width: 100%;
    height: 100%;
    background: #eaeaea;
}

#app {
    display: flex;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
}

.router {
    width: calc(100% - 100px);
}
</style>